import { Box, Stack } from '@mui/material';
import { LabelledTextInput } from 'features';

interface SearchBarProps {
	searchTitle: string;
	searchPlaceholder: string;
	selectTitle?: string;
	searchValue?: any;
	searchOnchangeEvent?: (e: React.FormEvent<HTMLInputElement>) => void;
	sortByChildren?: JSX.Element;
	selectChildren?: JSX.Element;
	selectPlaceholder?: string;
	clearText?: () => void;
	actionButton?: () => JSX.Element;
}

export const SearchBar = ({
	searchTitle,
	searchPlaceholder,
	sortByChildren,
	selectTitle,
	selectChildren,
	selectPlaceholder,
	searchOnchangeEvent,
	searchValue,
	clearText,
	actionButton,
}: SearchBarProps) => {
	return (
		<Stack direction="row" gap={2}>
			<Box width={'50%'}>
				<LabelledTextInput
					id="input"
					value={searchValue}
					placeholder={searchPlaceholder}
					type="search"
					title={searchTitle}
					onChangeEvent={searchOnchangeEvent}
					clearText={clearText}
					required={false}
				/>
			</Box>
			{actionButton && (
				<Box
					width={'50%'}
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'center'}
					alignItems={'end'}
				>
					{actionButton()}
				</Box>
			)}
			{sortByChildren && (
				<LabelledTextInput
					width="20%"
					id="input"
					value=""
					placeholder="Date"
					type="select"
					title="Sort by"
					required={false}
				>
					{sortByChildren}
				</LabelledTextInput>
			)}
			{selectTitle && (
				<LabelledTextInput
					width="30%"
					id="input"
					value=""
					placeholder={selectPlaceholder}
					type="select"
					title={selectTitle}
					required={false}
				>
					{selectChildren}
				</LabelledTextInput>
			)}
		</Stack>
	);
};
