import { useTheme, useMediaQuery } from '@mui/material';
import { DataGrid as MuiDataGrid, DataGridProps } from '@mui/x-data-grid';
import '../../../theme/base';
import { UilCheck, UilTimes } from '@iconscout/react-unicons';

interface CustomDataGridProps extends DataGridProps {
	ignoreMobile?: boolean;
}

export const DataGrid = ({ ignoreMobile, ...props }: CustomDataGridProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

	return (
		<MuiDataGrid
			slots={{
				noRowsOverlay: () => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							marginTop: '40px',
						}}>
						<em>No records to display</em>
					</div>
				),
			}}
			sx={{
				'.MuiDataGrid-columnSeparator': {
					display: 'none',
				},
				'&.MuiDataGrid-root': {
					border: 'none',
				},
				'& .MuiDataGrid-row:first-of-type': {
					borderTop: '1.6px solid',
					borderTopColor: theme.colors.alpha.grey[3],
				},
				'& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': {
					...(isMobile && !ignoreMobile && { display: 'none' }),
				},
			}}
			disableColumnFilter
			disableColumnMenu
			disableColumnSelector
			rowSelection={false}
			autoHeight
			pageSizeOptions={[3, 10, 20, 50, 100]}
			paginationMode='server'
			{...(isMobile && !ignoreMobile && { columnHeaderHeight: 0 })}
			{...props}
		/>
	);
};

export const renderActiveCellIcon = (isActive: boolean) =>
	isActive ? <UilCheck color={'#58DD5E'} /> : <UilTimes color={'#F92C2C'} />;
